































































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton

} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

@Component({
  name: 'ForgotPassword',
  components: {
    AppLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver
  }

})

export default class ForgotPassword extends Vue {
  $refs!: { forgotPasswordRules }

  private userEmail: string = ''
  private sideImg:string = require('@/assets/images/pages/forgot-password-v2.svg')

  private mounted () {
    const rules = [email, required]
  }

  private get imgUrl () {
    if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
      return this.sideImg
    }
    return this.sideImg
  }

  private validationForm () {
    this.$refs.forgotPasswordRules.validate().then(success => {
      // TODO call for rend forgot password link
      if (success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This is for UI purpose only.',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
      }
    })
  }
}
